























































































































import Common from "@/common/Common";
import { Component, Vue } from "vue-property-decorator";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import firebase from "firebase/app";
import "firebase/firestore";

@Component({
  components: {
    ClipLoader
  }
})
export default class ChatBot extends Vue {
  isLoading = false;
  errorMessage = "";
  chats: any = [];
  chatbot: any = [];
  uid: string = "";
  text: string = "チャットボットでは下記が選択されました。";

  mounted() {
    Promise.resolve()
      .then(() => {
        return new Promise((resolve, reject) => {
          Common.getUserInfo().then((userInfo: any) => {
            this.uid = userInfo.uid;
            resolve(userInfo.from);
          });
        });
      })
      .then(from => {
        firebase
          .firestore()
          .collection("chatbot")
          .doc(`${from}`)
          .collection("questions")
          .get()
          .then(result => {
            if (!result.empty) {
              result.docs.forEach(bot => {
                let obj = bot.data();
                obj.id = bot.id;
                obj.type = "question";
                this.chatbot.push(obj);
                if (obj.id == "cb0001") {
                  this.chats.push(obj);
                }
              });
            }
          });
      });
  }

  onAnswer(question: string, answer: any) {
    const sleep = (ms: number) =>
      new Promise(resolve => setTimeout(() => resolve(), ms));
    sleep(300)
      .then(() => {
        this.chats.push({
          type: "answer",
          answer: answer.answer
        });
      })
      .then(() => {
        this.$nextTick(() => {
          this.scrollBottom();
        });
      });
    this.text += `\n\n${question}\n>${answer.answer}`;
    if (answer.nextId != "") {
      sleep(300)
        .then(() => {
          this.chats.push(
            this.chatbot.find((item: any) => item.id == answer.nextId)
          );
        })
        .then(() => {
          this.$nextTick(() => {
            this.scrollBottom();
          });
        });
    }
    if (answer.onayamiId != "") {
      const ref = firebase
        .firestore()
        .collection("onayamis")
        .doc(`${answer.onayamiId}`);
      ref.get().then(onayami => {
        if (onayami.exists) {
          let obj = onayami.data()!;
          ref
            .collection("rooms")
            .add({
              creator: this.uid,
              clinic: obj.uid,
              patient: this.uid,
              uids: [this.uid, obj.uid],
              gids: [obj.gid],
              chatNum: 0,
              chatText: this.text += `\n\n-----\nお問い合わせの商品名などがお分かりでしたら事前にお送りいただけるとスムーズでございます。\nチャットのご対応時間は、10:30 〜 18:00 となっております。\nご返信にお時間がかかる場合がございます。\nまた、時間外のお問い合わせは、順次ご回答いたします。`,
              chatUid: "admin",
              chatStart: true,
              clinicChatNum: 1,
              studentChatNum: 0,
              chatCreateTime: firebase.firestore.FieldValue.serverTimestamp(),
              status: "before",
              hasReviewed: false
            })
            .then(value => {
              const chatId = value.id;
              ref
                .collection("rooms")
                .doc(chatId)
                .collection("chats")
                .add({
                  text: this.text,
                  createTime: firebase.firestore.FieldValue.serverTimestamp(),
                  uid: "admin",
                  partnerUid: "",
                  type: "admin_message",
                  read: true,
                  push: false
                })
                .then(() => {
                  this.$router.push(
                    `/onayamis/${answer.onayamiId}/chat/${chatId}`
                  );
                });
            });
        }
      });
    }
  }
  scrollBottom() {
    let obj = document.getElementById("chatContents")!;
    obj.scrollTop = obj.scrollHeight;
    if (window.innerWidth < 992) {
      obj.scrollIntoView(false);
    }
  }
}
